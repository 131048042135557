<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年"
        @change="
          getCollegeList(),
            (listQuery.college_id = null),
            (listQuery.major_id = null),
            (majors = []),
            (listQuery.grade_id = null),
            (grades = []),
            (listQuery.class_id = null),
            (classes = [])
        "
      >
      </el-date-picker>
      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        class="filter-item"
        clearable
        @change="
          getMajorList(),
            (listQuery.major_id = null),
            (majors = []),
            (listQuery.grade_id = null),
            (grades = []),
            (listQuery.class_id = null),
            (classes = [])
        "
      >
        <el-option
          v-for="item in colleges"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.major_id"
        placeholder="请选择专业"
        class="filter-item"
        clearable
        @change="
          getGradeList(),
            (listQuery.grade_id = null),
            (grades = []),
            (listQuery.class_id = null),
            (classes = [])
        "
      >
        <el-option
          v-for="item in majors"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>

      <!-- <el-select
        v-model="listQuery.semester"
        placeholder="请选择学期"
        clearable
        class="filter-item"
      >
        <el-option label="第一学期" :value="1" />
        <el-option label="第二学期" :value="2" />
      </el-select> -->

      <el-select
        v-model="listQuery.grade_id"
        placeholder="请选择年级"
        class="filter-item"
        clearable
        @change="getClassList(), (listQuery.class_id = null), (classes = [])"
      >
        <el-option
          v-for="item in grades"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>

      <el-select
        v-model="listQuery.class_id"
        placeholder="请选择班级"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in classes"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>

      <el-button class="filter-item" type="primary" @click="handleFilter">
        查找
      </el-button>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @sort-change="changeSort"
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.college.name }}
        </template>
      </el-table-column>
      <el-table-column label="专业" min-width="110" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.major.name }}
        </template>
      </el-table-column>
      <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school_year }}-{{ scope.row.school_year - 0 + 1 }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="学期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.grade.school_year }}
        </template>
      </el-table-column> -->
      <el-table-column label="年级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.grade.name }}
        </template>
      </el-table-column>
      <el-table-column label="班级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleClassSchedule(scope.row)"
            >班级课程表</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleStudentRoster(scope.row)"
            >学生花名册</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="teacherProfile(scope.row)"
            >教师资料</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        type: 2,
        school_id: null,
        college_id: null,
        major_id: null,
        school_year: null,
        semester: null,
        grade_id: null,
        class_id: null,
        college_name_sort: "",
        major_name_sort: "",
      },
      schools: [],
      academys: [],
      professionals: [],
      school_years: [],
      grades: [],
      classes: [],
      majors: [],
      listLoading: false,
      list: [],
      total: null,
    };
  },
  created() {
    // this.getCollegeList();
    this.getList();
  },
  methods: {
    //排序
    changeSort(e) {
      console.log(e);
      this.listQuery.college_name_sort = "";
      this.listQuery.major_name_sort = "";
      if (e.order == null) {
        this.getList();
        return;
      }
      switch (e.column.label) {
        case "院系":
          if (e.order == "ascending") {
            this.listQuery.college_name_sort = 0;
          } else {
            this.listQuery.college_name_sort = 1;
          }
          break;
        case "专业":
          if (e.order == "ascending") {
            this.listQuery.major_name_sort = 0;
          } else {
            this.listQuery.major_name_sort = 1;
          }
          break;
        default:
          break;
      }
      this.getList();
    },
    getCollegeList() {
      this.colleges = [];
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 999999999999999,
        },
      }).then((response) => {
        this.colleges = response.data.data;
        this.$forceUpdate(); //强制更新
      });
    },
    getMajorList() {
      request({
        url: "/api/schoolend/school/majorList",
        method: "get",
        params: {
          limit: 999999999999999,
          college_id: this.listQuery.college_id,
        },
      }).then((response) => {
        this.majors = response.data.data;
      });
    },
    getGradeList() {
      request({
        url: "/api/schoolend/school/gradeList",
        method: "get",
        params: {
          limit: 999999999999999,
          school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.grades = response.data.data;
      });
    },
    getClassList() {
      request({
        url: "/api/schoolend/school/classList",
        method: "get",
        params: {
          limit: 99999999999999999,
          college_id: this.listQuery.college_id,
          major_id: this.listQuery.major_id,
          grade_id: this.listQuery.grade_id,
          school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.classes = response.data.data;
      });
    },
    handleClassSchedule(row) {
      let data = JSON.stringify(row);
      this.$router.push(`/educationin/classschedule?data=` + data);
    },
    handleStudentRoster(row) {
      this.$router.push(`/educationin/studentroster?class_id=` + row.id);
    },
    teacherProfile(row) {
      this.$router.push(`/educationin/teacherprofile?class_id=` + row.id);
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/school/classList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.class_box {
  padding: 10px;
}
</style>
